<template>
  <div id="app">
    <div id="chat-container">
      <div id="chat-title">
        <span>{{ activeName }}</span>
      </div>
      <UserConversation />
      <SnackBar />
      <div v-if="activeName != ''" id="chat-form">
        <v-progress-circular
          v-bind:style="{ display: loader }"
          :size="36"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-input
          v-if="loader == 'none'"
          append-icon="mdi-send"
          prepend-icon="mdi-robot"
          @click:append="enterClicked"
          @click:prepend="reverseBot"
        >
          <input
            autocomplete="off"
            v-if="loader == 'none'"
            class="chat-form"
            @keyup.enter="enterClicked()"
            name="text"
            type="text"
            v-model="text"
            placeholder="Type..."
          />
        </v-input>
      </div>
    </div>
  </div>
</template>

<script>
//packages
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { mapState } from "vuex";
//components
import UserConversation from "@/components/chat/UserConversation.vue";
import SnackBar from "@/components/SnackBar.vue";
//ACTIONS
import { showSnackbar } from "@/globalActions";
//methods
import apiClient from "@/plugins/apiClient.js";

Vue.use(VueAxios, axios);
export default {
  name: "App",
  data() {
    return {
      search: "",
      text: "",
      loader: "none"
    };
  },
  methods: {
    enterClicked() {
      if (this.text === "") {
        showSnackbar("Please Enter Something");
      } else {
        let currentObj = this;
        currentObj.loader = "";
        apiClient
          .sendMessage(this.text, localStorage.id) //msg, psid
          .then(function(response) {
            console.log(JSON.stringify(response.data));
            currentObj.text = "";
            currentObj.loader = "none";
            showSnackbar("Sent!");
          })
          .catch(function(error) {
            currentObj.text = "";
            console.log(error);
            showSnackbar("Some Issue");
          });
      }
    },
    reverseBot() {
      let currentObj = this;
      currentObj.loader = "";
      apiClient
        .reverseBot(localStorage.id) //psid
        .then(function(response) {
          console.log(JSON.stringify(response.data));
          currentObj.loader = "none";
          showSnackbar("Bot reversed!");
        })
        .catch(function(error) {
          console.log(error);
          showSnackbar("Some Issue");
        });
    }
  },
  computed: {
    ...mapState(["activeName"])
  },
  components: {
    UserConversation,
    SnackBar
  }
};
</script>

<style>
#chat-container {
  display: grid;
  grid:
    "search-container chat-title" 71px
    "conversation-list chat-message-list" 1fr
    "new-message-container chat-form" 78px / 0px 1fr;
  flex: 1 0 0;
  max-width: 100%;
  height: 90vh;
  min-height: 90vh;
  max-height: 90vh;
  border-radius: 10px;
}
#chat-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: chat-title;
  color: #dbdbdb;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0 10px 0 0;
  border-bottom: 1px solid rgba(250, 246, 246, 0.788);
  box-shadow: 0 1px 3px -1px rgba(236, 236, 236, 0.75);
  padding: 0 20px;
  margin-bottom: 5px;
}
#chat-form {
  display: flex;
  align-items: center;
  grid-area: chat-form;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 0;
  padding-left: 42px;
  padding-right: 22px;
}
#chat-form > img {
  width: 42px;
  height: 42px;
  margin-right: 15px;
}
#chat-form input {
  flex: 1 0 0;
  outline: none;
  padding: 15px;
  border: 2px solid #ddd;
  color: #ddd;
  border-radius: 6px;
  font-size: 1rem;
}
.v-messages {
  display: none !important;
}

.v-snack__content {
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  min-height: 100px;
}
</style>
