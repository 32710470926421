<template>
  <div id="chat-message-list">
    <ul>
      <li
        id="chat-message-list"
        v-for="conversation in userConversations"
        v-bind:key="conversation._id"
      >
        <div
          v-if="conversation.from == 'user'"
          class="message-row other-message"
        >
          <div class="message-content">
            <img src="images/profiles/daryl.png" alt="Daryl Duckmanton" />
            <div class="message-text">{{ conversation.message }}</div>
            <div class="message-time">
              {{ conversation.createdAt | moment("from", "now") }}
            </div>
          </div>
        </div>
        <div v-else class="message-row you-message">
          <div class="message-content">
            <div class="message-text">{{ conversation.message }}</div>
            <div class="message-time">
              {{ conversation.createdAt | moment("from", "now") }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

Vue.use(require("vue-moment"));

export default {
  name: "UserConversation",
  watch: {
    conversations: function() {
      if (localStorage.id) {
        let activeUser = this.conversations.find(
          o => o.profile_id == localStorage.id
        );
        let activename = `${activeUser.profile_name} | Bot Active: ${activeUser.isBotActive}`;
        this.$store.commit("SET_ACTIVEUSER", activename);
        console.log(activeUser.conversations);
        this.$store.commit("SET_ACTIVECONVERSATION", activeUser.conversations);
      }
    }
  },
  computed: {
    ...mapState(["userConversations", "conversations"])
  }
};
</script>

<style scoped>
#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 4px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.message-row {
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 10px;
}
.message-content {
  display: grid;
}
.you-message {
  justify-content: end;
}
.you-message .message-content {
  justify-items: end;
}
.other-message .message-content {
  grid-template-columns: 44px 1fr;
  grid-column-gap: 10px;
}
.message-row img {
  border-radius: 100%;
  grid-row: span 2;
}
.message-time {
  font-size: 0.55rem;
  color: rgb(192, 189, 189);
}
.message-text {
  padding: 9px 14px;
  font-size: 0.85rem;
  margin-bottom: 3px;
}
.you-message .message-text {
  background: #0048aa;
  color: #eee;
  border: 1px solid #0048aa;
  border-radius: 14px 14px 0 14px;
}
.other-message .message-text {
  background: #eee;
  color: #111;
  border: 1px solid #ddd;
  border-radius: 14px 14px 14px 0;
}
</style>
